import React from 'react';
import Layout from "../components/layout/layout";
import Img from "gatsby-image";
import Seo from "../components/seo/seo"


export default ({data, location}) => (
  <Layout title="El Último Mono" location={location}>
    <Seo title="El Último Mono" description={data.site.siteMetadata.description} />
    <div className="ph6-l ph2-m ph2 pv4 sans-serif center">
      <ul className="flex flex-wrap">
        <li className="w5 pa3 db center mw5 tc black link">
          <a
              title="LOS ASQUEROSOS"
              href="https://open.spotify.com/album/6Zgxs8bzENwPOlxMZnU76N?si=c8OgdxYwQpCR6Z3J7_KVxA">
            <Img fluid={data.asquerosos.childImageSharp.fluid} className="w-100 mw9 center"/>

            <dl className="mt2 f6 lh-copy">
              <dt className="clip">Title</dt>
              <dd className="ml0">LOS ASQUEROSOS (2022)</dd>
              <dt className="clip">Artist</dt>
              <dd className="ml0 gray">POLAR RECORDS</dd>
            </dl>
          </a>
        </li>
        <li className="w5 pa3 db center mw5 tc black link">
          <a
              title="EL MURO"
              href="https://open.spotify.com/album/5NFgnSzlwcagZqS9EYkWxQ?si=ca368823b9fe4d75">
            <Img fluid={data.muro.childImageSharp.fluid} className="w-100 mw9 center"/>

            <dl className="mt2 f6 lh-copy">
              <dt className="clip">Title</dt>
              <dd className="ml0">EL MURO (2021)</dd>
              <dt className="clip">Artist</dt>
              <dd className="ml0 gray">POLAR RECORDS</dd>
            </dl>
          </a>
        </li>
        <li className="w5 pa3 db center mw5 tc black link">
          <a
              title="PRÉNDELO OTRA VEZ"
              href="https://open.spotify.com/album/35d7cuFXMGmPbIJjGSyIoS?si=txrPT6R_TNivIYsAm360PQ">
            <Img fluid={data.prendelo.childImageSharp.fluid} className="w-100 mw9 center"/>

            <dl className="mt2 f6 lh-copy">
              <dt className="clip">Title</dt>
              <dd className="ml0">PRÉNDELO OTRA VEZ (2020)</dd>
              <dt className="clip">Artist</dt>
              <dd className="ml0 gray">POLAR RECORDS</dd>
            </dl>
          </a>
        </li>
        <li className="w5 pa3 db center mw5 tc black link">
          <a className="db center mw5 tc black link"
             title="COSAS QUE TODO EL MUNDO SABÍA MENOS YO"
             href="https://open.spotify.com/album/6lle3ACDJFqWTsCEwMfY42?si=OaNnSec0RDW_DcX0VeHMgA">
            <Img fluid={data.cosas.childImageSharp.fluid} className="w-100 mw9 center"/>

            <dl className="mt2 f6 lh-copy">
              <dt className="clip">Title</dt>
              <dd className="ml0">COSAS QUE TODO EL MUNDO SABÍA MENOS YO (2018)</dd>
              <dt className="clip">Artist</dt>
              <dd className="ml0 gray">POLAR RECORDS</dd>
            </dl>
          </a>
        </li>
      </ul>
    </div>
  </Layout>
)

export const query = graphql`
    query {
      muro: file(relativePath: { eq: "muro.jpeg" }) {
              childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 1200) {
                      ...GatsbyImageSharpFluid
                  }
              }
        }
        asquerosos: file(relativePath: { eq: "asquerosos.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        prendelo: file(relativePath: { eq: "prendelo.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cosas: file(relativePath: { eq: "cosas_que_todo.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        site {
            siteMetadata {
                description
            }
        }
    }
`
